import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  methods: {
    handleError({response : { data : error } }) {
      let message= (!error || !Object.keys(error).length) ? 'Terdapat permasalahan disisi backend, harap hubungi developer !!' : error.error 

      if(error.error.parent){
        const {parent : {sqlMessage}}=error.error
        message=sqlMessage
      }else if(error.error.errors){
        message=error.error.errors[0].message
      }
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Oops!',
          text: message,
          icon: 'CheckIcon',
          variant: 'danger',
        },
      },{
        position:'bottom-right'
      })

    }
  }
}