export default [
  {
    path: 'jenis-biaya',
    redirect: { name: 'expenses' },
    component: { template: '<router-view />' },
    children: [
      {
        path: '/',
        name: 'expenses',
        component: () => import('@pages/expenses/Index.vue'),
        meta: {
          auth:true,
          pageTitle: 'Jenis Biaya',
          breadcrumb: [
            {
              text: 'Setting',
            },
            {
              text: 'Jenis Biaya',
              active: true
            },
          ],
        }
      },
      {
        path: 'tambah',
        name: 'expenses-add',
        component: () => import('@pages/expenses/Form.vue'),
        meta: {
          auth:true,
          pageTitle: 'Tambah Jenis Biaya',
          navActiveLink:'expenses',
          breadcrumb: [
            {
              text: 'Setting',
            },
            {
              text: 'Jenis Biaya',
              to: { name: 'expenses' },
            },
            {
              text: 'Tambah',
              active: true
            },
          ],
        }
      },
      {
        path: ':id',
        name: 'expenses-edit',
        component: () => import('@pages/expenses/Form.vue'),
        meta: {
          auth:true,
          pageTitle: 'Edit Jenis Biaya',
          navActiveLink:'expenses',
          breadcrumb: [
            {
              text: 'Setting',
            },
            {
              text: 'Jenis Biaya',
              to: { name: 'expenses' },
            },
            {
              text: 'Edit',
              active: true
            },
          ],
        }
      }
    ]
  }
]