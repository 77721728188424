export default [
  {
    path: 'cabang',
    redirect: { name: 'companies' },
    component: { template: '<router-view />' },
    children: [
      {
        path: '/',
        name: 'companies',
        component: () => import('@pages/companies/Index.vue'),
        meta: {
          auth:true,
          pageTitle: 'Cabang',
          breadcrumb: [
            {
              text: 'Setting',
            },
            {
              text: 'Cabang',
              active: true
            },
          ],
        }
      },
      {
        path: 'tambah',
        name: 'companies-add',
        component: () => import('@pages/companies/Form.vue'),
        meta: {
          auth:true,
          pageTitle: 'Tambah Cabang',
          navActiveLink:'companies',
          breadcrumb: [
            {
              text: 'Setting',
            },
            {
              text: 'Cabang',
              to: { name: 'companies' },
            },
            {
              text: 'Tambah',
              active: true
            },
          ],
        }
      },
      {
        path: ':id/edit',
        name: 'companies-edit',
        component: () => import('@pages/companies/Form.vue'),
        meta: {
          auth:true,
          pageTitle: 'Edit Cabang',
          navActiveLink:'companies',
          breadcrumb: [
            {
              text: 'Setting',
            },
            {
              text: 'Cabang',
              to: { name: 'companies' },
            },
            {
              text: 'Edit',
              active: true
            },
          ],
        }
      },
      {
        path: ':id',
        component: () => import('@pages/companies/Show.vue'),
        redirect: { name: 'companies-show' },
        children: [
          {
            path: 'info',
            name: 'companies-show',
            component: () => import('@pages/companies/page/Info.vue'),
            meta: {
              auth:true,
              pageTitle: 'Detail Cabang',
              navActiveLink:'companies',
              breadcrumb: [
                {
                  text: 'Setting',
                },
                {
                  text: 'Cabang',
                  to: { name: 'companies' },
                },
                {
                  text: 'Info Detail',
                  active: true
                },
              ],
            }
          },
          {
            path: 'format-penomoran',
            name: 'companies-numbering',
            component: () => import('@pages/companies/page/Numbering.vue'),
            meta: {
              auth:true,
              pageTitle: 'Penomoran Cabang',
              navActiveLink:'companies',
              breadcrumb: [
                {
                  text: 'Setting',
                },
                {
                  text: 'Cabang',
                  to: { name: 'companies' },
                },
                {
                  text: 'Penomoran Cabang',
                  active: true
                },
              ],
            }
          },
          {
            path: 'format-penomoran/:number',
            name: 'companies-numbering.show',
            component: () => import('@pages/companies/page/NumberingDetail.vue'),
            meta: {
              auth:true,
              pageTitle: 'Detail Penomoran Cabang',
              navActiveLink:'companies',
              breadcrumb: [
                {
                  text: 'Setting',
                },
                {
                  text: 'Cabang',
                  to: { name: 'companies' },
                },
                {
                  text: 'Penomoran Cabang',
                  to: {name: 'companies-numbering'}
                },
                {
                  text: 'Detail',
                  active: true
                },
              ],
            }
          },
          {
            path: 'threshold',
            name: 'companies-threshold',
            component: () => import('@pages/companies/page/Threshold.vue'),
            meta: {
              auth:true,
              pageTitle: 'Threshold Cabang',
              navActiveLink:'companies',
              breadcrumb: [
                {
                  text: 'Setting',
                },
                {
                  text: 'Cabang',
                  to: { name: 'companies' },
                },
                {
                  text: 'Threshold',
                  active: true
                },
              ],
            }
          },
        ]
      }

    ]
  }
]