export default [
  {
    path: 'pengguna',
    redirect: { name: 'users' },
    component: { template: '<router-view />' },
    children: [
      {
        path: '/',
        name: 'users',
        component: () => import('@pages/users/Index.vue'),
        meta: {
          auth:true,
          pageTitle: 'Pengguna',
          breadcrumb: [
            {
              text: 'Setting',
            },
            {
              text: 'Pengguna',
              active: true
            },
          ],
        }
      },
      {
        path: 'tambah',
        name: 'users-add',
        component: () => import('@pages/users/Form.vue'),
        meta: {
          auth:true,
          pageTitle: 'Tambah Pengguna',
          navActiveLink:'users',
          breadcrumb: [
            {
              text: 'Setting',
            },
            {
              text: 'Pengguna',
              to: { name: 'users' },
            },
            {
              text: 'Tambah',
              active: true
            },
          ],
        }
      },
      {
        path: ':id/edit',
        name: 'users-edit',
        component: () => import('@pages/users/Form.vue'),
        meta: {
          auth:true,
          pageTitle: 'Edit Pengguna',
          navActiveLink:'users',
          breadcrumb: [
            {
              text: 'Setting',
            },
            {
              text: 'Pengguna',
              to: { name: 'users' },
            },
            {
              text: 'Edit',
              active: true
            },
          ],
        }
      },
      {
        path: ':id',
        component: () => import('@pages/users/Show.vue'),
        redirect: { name: 'users-show' },
        children: [
          {
            path: 'info',
            name: 'users-show',
            component: () => import('@pages/users/page/Info.vue'),
            meta: {
              auth:true,
              pageTitle: 'Info Pengguna',
              navActiveLink:'users',
              breadcrumb: [
                {
                  text: 'Setting',
                },
                {
                  text: 'Pengguna',
                  to: { name: 'users' },
                },
                {
                  text: 'Info',
                  active: true
                },
              ],
            }
          },
          {
            path: 'ganti-password',
            name: 'users-password',
            component: () => import('@pages/users/page/Password.vue'),
            meta: {
              auth:true,
              pageTitle: 'Ganti Password Pengguna',
              navActiveLink:'users',
              breadcrumb: [
                {
                  text: 'Setting',
                },
                {
                  text: 'Pengguna',
                  to: { name: 'users' },
                },
                {
                  text: 'Ganti Password',
                  active: true
                },
              ],
            }
          },
          {
            path: 'privilege',
            name: 'users-privilege',
            component: () => import('@pages/users/page/Privilege.vue'),
            meta: {
              auth:true,
              pageTitle: 'Privilege Pengguna',
              navActiveLink:'users',
              breadcrumb: [
                {
                  text: 'Setting',
                },
                {
                  text: 'Pengguna',
                  to: { name: 'users' },
                },
                {
                  text: 'Privilege',
                  active: true
                },
              ],
            }
          },
          {
            path: 'log',
            name: 'users-log',
            component: () => import('@pages/users/page/Log.vue'),
            meta: {
              auth:true,
              pageTitle: 'Log Pengguna',
              navActiveLink:'users',
              breadcrumb: [
                {
                  text: 'Setting',
                },
                {
                  text: 'Pengguna',
                  to: { name: 'users' },
                },
                {
                  text: 'Log',
                  active: true
                },
              ],
            }
          }

        ]
      },
    ]
  },
  {
    path: 'jabatan',
    name: 'usergroups',
    component: () => import('@pages/users/usergroups/Index.vue'),
    meta: {
      auth:true,
      pageTitle: 'Jabatan Pengguna',
      navActiveLink:'users',
      breadcrumb: [
        {
          text: 'Setting',
        },
        {
          text: 'Pengguna',
          to: { name: 'users' },
        },
        {
          text: 'Jabatan',
          active: true
        },
      ],
    },
    children: [
      {
        path: ':id',
        name: 'usergroups-show',
        component: () => import('@pages/users/usergroups/Privilege.vue'),
        meta: {
          auth:true,
          pageTitle: 'Jabatan Pengguna',
          navActiveLink:'users',
          breadcrumb: [
            {
              text: 'Setting',
            },
            {
              text: 'Pengguna',
              to: { name: 'users' },
            },
            {
              text: 'Jabatan',
              to: {name: 'usergroups'}
            },
            {
              text: 'Privilege',
              active: true
            },
          ],
        },
      }
    ]
  },
]