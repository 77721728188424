import { mapState } from "vuex";
import _ from 'lodash'

export default {
  computed: {
    ...mapState({
      privileges: state => state.auth.cek
    }),
    isPusat() {
      const profile = this.$store.state.auth.profile
      if(profile.is_main==1||!profile.id_branch) return true
      else return false
    },
    isRegional() {
      const profile = this.$store.state.auth.profile
      if (profile.id_regional && profile.is_main == 0) return true
      else return false
    },
    mainRegionalId() {
      const profile = this.$store.state.auth.profile
      if (profile.is_main == 0) {
        if (profile.id_regional) return profile.id_regional
        else return ''
      }
      else return ''
    },
    idBranchArray() {
      const branches = this.$store.state.auth.profile.user_branches
      if (branches) return _.map(branches,'id_branch')
      else return []
    }
  },
  methods: {
    cP(privilegeId) {
      return this.privileges.includes(privilegeId)
    }
  }
}