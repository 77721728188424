export default [
  {
    path: 'operasional-masuk',
    redirect: { name: 'operationalins' },
    component: { template: '<router-view />' },
    children: [
      {
        path: '/',
        name: 'operationalins',
        component: () => import('@pages/operationalins/Index.vue'),
        meta: {
          auth:true,
          pageTitle: 'Operasional Masuk',
          breadcrumb: [
            {
              text: 'Operasional',
            },
            {
              text: 'Operasional Masuk',
              active: true
            },
          ],
        }
      },
      {
        path: 'tambah',
        name: 'operationalins-add',
        component: () => import('@pages/operationalins/Form.vue'),
        meta: {
          auth:true,
          pageTitle: 'Tambah Operasional Masuk',
          navActiveLink: 'operationalins',
          quickName: 'Operasional In',
          breadcrumb: [
            {
              text: 'Operasional',
            },
            {
              text: 'Operasional Masuk',
              to: { name: 'operationalins' },
            },
            {
              text: 'Tambah',
              active: true
            },
          ],
        }
      },
      {
        path: ':id',
        name: 'operationalins-edit',
        component: () => import('@pages/operationalins/Form.vue'),
        meta: {
          auth:true,
          pageTitle: 'Edit Operasional Masuk',
          navActiveLink: 'operationalins',
          isEdit: true,
          breadcrumb: [
            {
              text: 'Operasional',
            },
            {
              text: 'Operasional Masuk',
              to: { name: 'operationalins' },
            },
            {
              text: 'Edit',
              active: true
            },
          ],
        }
      },
      {
        path: ':id/persetujuan',
        name: 'operationalins-approve',
        component: () => import('@pages/operationalins/Form.vue'),
        meta: {
          auth:true,
          pageTitle: 'Persetujuan Operasional Masuk',
          navActiveLink: 'operationalins',
          isApprove: true,
          breadcrumb: [
            {
              text: 'Operasional',
            },
            {
              text: 'Operasional Masuk',
              to: { name: 'operationalins' },
            },
            {
              text: 'Persetujuan',
              active: true
            },
          ],
        }
      }
    ]
  }
]