export default [
  {
    path: 'komisi-pembayaran-mitra',
    redirect: { name: 'commissions' },
    component: { template: '<router-view />' },
    children: [
      {
        path: '',
        name: 'commissions',
        component: () => import('@pages/commissions/Index.vue'),
        meta: {
          auth:true,
          pageTitle: 'Komisi Mitra',
          navActiveLink:'partners',
          breadcrumb: [
            {
              text: 'Setting',
            },
            {
              text: 'Mitra Pembayaran',
              to:{name:'partners'}
            },
            {
              text: 'Komisi Mitra',
              active: true
            },
          ],
        }
      },
      {
        path: 'tambah',
        name: 'commissions-add',
        component: () => import('@pages/commissions/Form.vue'),
        meta: {
          auth:true,
          pageTitle: 'Tambah Komisi Mitra',
          navActiveLink:'partners',
          breadcrumb: [
            {
              text: 'Setting',
            },
            {
              text: 'Mitra Pembayaran',
              to:{name:'partners'}
            },
            {
              text: 'Komisi Mitra',
              to: { name: 'commissions' },
            },
            {
              text: 'Tambah',
              active: true
            },
          ],
        }
      },
      {
        path: ':id',
        name: 'commissions-edit',
        component: () => import('@pages/commissions/Form.vue'),
        meta: {
          auth:true,
          pageTitle: 'Edit Komisi Mitra',
          navActiveLink:'partners',
          breadcrumb: [
            {
              text: 'Setting',
            },
            {
              text: 'Mitra Pembayaran',
              to:{name:'partners'}
            },
            {
              text: 'Komisi Mitra',
              to: { name: 'commissions' },
            },
            {
              text: 'Edit',
              active: true
            },
          ],
        }
      }
    ]
  }
]