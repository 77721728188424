import operationalouts from './operationals/operationalouts'
import operationalins from './operationals/operationalins'

export default [
  {
    path: 'operasional',
    redirect: { name: 'operationalins' },
    component: { template: '<router-view />' },
    children: [
      ...operationalouts,
      ...operationalins
    ]
  }
]