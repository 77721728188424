import Vue from 'vue'
import { ToastPlugin, ModalPlugin, LayoutPlugin, OverlayPlugin,BootstrapVueIcons } from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'

import router from './router'
import store from './store'
import App from './App.vue'

// Global Components
import './global-components'

// 3rd party plugins
import '@/libs/portal-vue'
import '@/libs/toastification'
import '@/libs/sweet-alerts'


// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)
Vue.use(LayoutPlugin)
Vue.use(OverlayPlugin)
Vue.use(BootstrapVueIcons)

// Composition API
Vue.use(VueCompositionAPI)

// toast custom
import toastC from './mixins/toast'
import privilegeMixin from './mixins/privilege'
import handleError from './mixins/handleError'
import './registerServiceWorker'
Vue.mixin(toastC)
Vue.mixin(privilegeMixin)
Vue.mixin(handleError)

require('@core/assets/fonts/feather/iconfont.css') // For form-wizard

// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')

Vue.config.productionTip = false
import MyCard from '@/my-components/MyCard.vue'
Vue.component('my-card', MyCard)

// moment
const moment = require('moment')
require('moment/locale/id')
Vue.use(require('vue-moment'), {
  moment
})
Vue.filter('fulldate', function (value) {
  if (!value) return ''
  return moment(value).format("dddd, DD MMMM YYYY - hh:mm:ss")
})
Vue.filter('mindate', function (value) {
  if (!value) return ''
  return moment(value).format("dddd, DD MMMM YYYY")
})

// numeral
const numeral = require('numeral')
Vue.filter('formatNumber', function (value) {
  if (!value) return "0"
  return numeral(value).format("0,0.00")
})

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
