export default [
  {
    path: 'transfer-keluar',
    redirect: { name: 'transferouts' },
    component: { template: '<router-view />' },
    children: [
      {
        path: '/',
        name: 'transferouts',
        component: () => import('@pages/transferouts/Index.vue'),
        meta: {
          auth:true,
          pageTitle: 'Transfer Keluar',
          breadcrumb: [
            {
              text: 'Inventori',
            },
            {
              text: 'Transfer Keluar',
              active: true
            },
          ],
        }
      },
      {
        path: 'tambah',
        name: 'transferouts-add',
        component: () => import('@pages/transferouts/Form.vue'),
        meta: {
          auth:true,
          pageTitle: 'Tambah Transfer Keluar',
          navActiveLink:'transferouts',
          breadcrumb: [
            {
              text: 'Inventori',
            },
            {
              text: 'Transfer Keluar',
              to: { name: 'transferouts' },
            },
            {
              text: 'Tambah',
              active: true
            },
          ],
        }
      },
      {
        path: ':id',
        name: 'transferouts-show',
        component: () => import('@pages/transferouts/Form.vue'),
        meta: {
          auth: true,
          isShow: true,
          pageTitle: 'Detail Transfer Keluar',
          navActiveLink:'transferouts',
          breadcrumb: [
            {
              text: 'Inventori',
            },
            {
              text: 'Transfer Keluar',
              to: { name: 'transferouts' },
            },
            {
              text: 'Detail',
              active: true
            },
          ],
        }
      },
      {
        path: ':id/edit',
        name: 'transferouts-edit',
        component: () => import('@pages/transferouts/Form.vue'),
        meta: {
          auth: true,
          isEdit: true,
          pageTitle: 'Edit Transfer Keluar',
          navActiveLink:'transferouts',
          breadcrumb: [
            {
              text: 'Inventori',
            },
            {
              text: 'Transfer Keluar',
              to: { name: 'transferouts' },
            },
            {
              text: 'Edit',
              active: true
            },
          ],
        }
      },
      {
        path: ':id/terima-sebagian',
        name: 'transferouts-part',
        component: () => import('@pages/transferouts/Form.vue'),
        meta: {
          auth: true,
          isPart: true,
          pageTitle: 'Tambah Transfer Keluar',
          navActiveLink:'transferouts',
          breadcrumb: [
            {
              text: 'Inventori',
            },
            {
              text: 'Transfer Keluar',
              to: { name: 'transferouts' },
            },
            {
              text: 'Tambah Sebagian',
              active: true
            },
          ],
        }
      },
    ]
  }
]