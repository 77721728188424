import { getField, updateField } from 'vuex-map-fields'
import _ from 'lodash'
const defaultFilter = {
  id_regional: [],
  name_regional: "",
  id_branch: [],
  name_branch: "",
  id_category: [],
  name_category: "",
  id_item: [],
  name_item: "",
  date_1: "",
  date_2: "",
  id_user: [],
  user_create: "",
}

const state = {
  filter: JSON.parse(JSON.stringify(defaultFilter)),
  filterBranchOptions:[],
  filterRegionalOptions:[],
  filterUserOptions:[],
  filterItemOptions:[],
  filterCategoryOptions:[],
}
const mutations = {
  SET_OPTION_BRANCH(state, data) {
    if (_.isArray(data)) state.filterBranchOptions = data
    else state.filterBranchOptions = [data]
  },
  SET_OPTION_REGIONAL(state, data) {
    if (_.isArray(data)) state.filterRegionalOptions = data
    else state.filterRegionalOptions = [data]
  },
  SET_OPTION_USER(state, data) {
    if (_.isArray(data)) state.filterUserOptions = data
    else state.filterUserOptions = [data]
  },
  SET_OPTION_ITEM(state, data) {
    if (_.isArray(data)) state.filterItemOptions = data
    else state.filterItemOptions = [data]
  },
  SET_OPTION_CATEGORY(state, data) {
    if (_.isArray(data)) state.filterCategoryOptions = data
    else state.filterCategoryOptions = [data]
  },
  updateField
}
const getters = {
  getField,
  isFiltered(state) {
    return !_.isMatch(state.filter,defaultFilter)
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  getters,
}