export default [
  {
    path: 'pembelian',
    redirect: { name: 'purchases' },
    component: { template: '<router-view />' },
    children: [
      {
        path: '/',
        name: 'purchases',
        component: () => import('@pages/purchases/Index.vue'),
        meta: {
          auth:true,
          pageTitle: 'Pembelian',
          breadcrumb: [
            {
              text: 'Inventori',
            },
            {
              text: 'Pembelian',
              active: true
            },
          ],
        }
      },
      {
        path: 'tambah',
        name: 'purchases-add',
        component: () => import('@pages/purchases/Form.vue'),
        meta: {
          auth:true,
          pageTitle: 'Tambah Pembelian',
          navActiveLink:'purchases',
          breadcrumb: [
            {
              text: 'Inventori',
            },
            {
              text: 'Pembelian',
              to: { name: 'purchases' },
            },
            {
              text: 'Tambah',
              active: true
            },
          ],
        }
      },
      {
        path: ':id',
        name: 'purchases-edit',
        component: () => import('@pages/purchases/Form.vue'),
        meta: {
          auth: true,
          isEdit: true,
          pageTitle: 'Edit Pembelian',
          navActiveLink:'purchases',
          breadcrumb: [
            {
              text: 'Inventori',
            },
            {
              text: 'Pembelian',
              to: { name: 'purchases' },
            },
            {
              text: 'Edit',
              active: true
            },
          ],
        }
      },
      {
        path: ':id/info',
        name: 'purchases-show',
        component: () => import('@pages/purchases/Form.vue'),
        meta: {
          auth: true,
          isShow: true,
          pageTitle: 'Detail Pembelian',
          navActiveLink:'purchases',
          breadcrumb: [
            {
              text: 'Inventori',
            },
            {
              text: 'Pembelian',
              to: { name: 'purchases' },
            },
            {
              text: 'Detail',
              active: true
            },
          ],
        }
      },
      {
        path: ':id/approval',
        name: 'purchases-approval',
        component: () => import('@pages/purchases/Form.vue'),
        meta: {
          auth: true,
          isApprove: true,
          pageTitle: 'Approve Pembelian',
          navActiveLink:'purchases',
          breadcrumb: [
            {
              text: 'Inventori',
            },
            {
              text: 'Pembelian',
              to: { name: 'purchases' },
            },
            {
              text: 'Approval',
              active: true
            },
          ],
        }
      }
    ]
  }
]