export default [
  {
    path: 'stok-opname',
    redirect: { name: 'stockopnames' },
    component: { template: '<router-view />' },
    children: [
      {
        path: '/',
        name: 'stockopnames',
        component: () => import('@pages/stockopnames/Index.vue'),
        meta: {
          auth:true,
          pageTitle: 'Stok Opname',
          breadcrumb: [
            {
              text: 'Inventori',
            },
            {
              text: 'Stok Opname',
              active: true
            },
          ],
        }
      },
      {
        path: 'tambah',
        name: 'stockopnames-add',
        component: () => import('@pages/stockopnames/Form.vue'),
        meta: {
          auth: true,
          isAdd: true,
          pageTitle: 'Tambah Stok Opname',
          navActiveLink:'stockopnames',
          breadcrumb: [
            {
              text: 'Inventori',
            },
            {
              text: 'Stok Opname',
              to: { name: 'stockopnames' },
            },
            {
              text: 'Tambah',
              active: true
            },
          ],
        }
      },
      {
        path: ':id',
        name: 'stockopnames-show',
        component: () => import('@pages/stockopnames/Show.vue'),
        meta: {
          auth: true,
          pageTitle: 'Detail Stok Opname',
          navActiveLink:'stockopnames',
          breadcrumb: [
            {
              text: 'Inventori',
            },
            {
              text: 'Stok Opname',
              to: { name: 'stockopnames' },
            },
            {
              text: 'Detail',
              active: true
            },
          ],
        }
      },
      {
        path: ':id/edit',
        name: 'stockopnames-edit',
        component: () => import('@pages/stockopnames/Form.vue'),
        meta: {
          auth: true,
          isEdit: true,
          pageTitle: 'Edit Stok Opname',
          navActiveLink:'stockopnames',
          breadcrumb: [
            {
              text: 'Inventori',
            },
            {
              text: 'Stok Opname',
              to: { name: 'stockopnames' },
            },
            {
              text: 'Edit',
              active: true
            },
          ],
        }
      },
    ]
  }
]