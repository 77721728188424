import { v4 as uuidv4 } from 'uuid'
import _ from 'lodash'
import axios from '@axios'
const paginate = require('paginate-array')

const state = {
  salePriceArray: [],
  salePricePage: 1,
  draft_notification: {
    "inventori": 0,
    "stok_masuk": 0,
    "prm_stok_masuk": 0,
    "pembelian": 0,
    "penerimaan": 0,
    "stok_keluar": 0,
    "transfer_keluar": 0,
    "stok_keluar_p": 0,
    "ops": 0,
    "ops_masuk": 0,
    "ops_keluar": 0
  }
}
const mutations = {
  SET_SALE_PRICE_ARRAY(state, data) {
    const { branch, partners } = data
    let newArray = []
    for (let i = 0; i < branch.length; i++) {
      const b = branch[i];
      let branchArray = []
      for (let x = 0; x < partners.length; x++) {
        const p = partners[x];
        branchArray.push({
          id_branch: b.id,
          name_branch: b.name,
          id_payment_partner: p.id,
          name_payment_partner: p.name,
          price: 0,
          uniqid: uuidv4()
        })
      }
      newArray.push({
        label: b.name,
        value: b.id,
        details: branchArray
      })
    }
    state.salePriceArray = newArray
  },
  SET_NEW_PRICE_ARRAY(state, data) {
    let flatten = _.flatMapDeep(state.salePriceArray, (n) => { return n.details })
    for (let i = 0; i < data.length; i++) {
      const e = data[i];
      let find = flatten.find(d => d.id_branch == e.id_branch && d.id_payment_partner == e.id_payment_partner)
      if(find) find = Object.assign(find,{price:e.price})
    }
  },
  UPDATE_VALUE(state, payload) {
    let find = _.flatMapDeep(state.salePriceArray, (n) => {return n.details}).find(e => e.uniqid == payload.uniqid)
    if (find) {
      if (payload.value && !isNaN(payload.value)) {
        find = Object.assign(find,{price: parseFloat(payload.value)})
      } else {
        find = Object.assign(find,{price: 0})
      }
    }
  },
  CHANGE_PAGE(state, page) {
    state.salePricePage = page
  },
  DESTROY_SALE_PRICE_ARRAY(state) {
    state.salePriceArray = []
    state.salePricePage = 1
  },
  SET_DRAFT_NOTIFICATION(state, data) {
    state.draft_notification = data
  }
}
const getters = {
  paginateSalePrice: (state) => {
    return paginate(state.salePriceArray,state.salePricePage,2)
  }
}
const actions = {
  async getDraftNotification({commit}) {
    try {
      const { data } = await axios.get(`v1/draft_notifs`)
      commit('SET_DRAFT_NOTIFICATION', data)
      return Promise.resolve(data)
    } catch (error) {
      return Promise.reject(error)
    }
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions
}