export default [
  {
    path: 'persediaan-awal',
    redirect: { name: 'openingbalances' },
    component: { template: '<router-view />' },
    children: [
      {
        path: '/',
        name: 'openingbalances',
        component: () => import('@pages/openingbalances/Index.vue'),
        meta: {
          auth:true,
          pageTitle: 'Persediaan Awal',
          breadcrumb: [
            {
              text: 'Inventori',
            },
            {
              text: 'Persediaan Awal',
              active: true
            },
          ],
        }
      },
      {
        path: 'tambah',
        name: 'openingbalances-add',
        component: () => import('@pages/openingbalances/Form.vue'),
        meta: {
          auth:true,
          pageTitle: 'Tambah Persediaan Awal',
          navActiveLink:'openingbalances',
          breadcrumb: [
            {
              text: 'Inventori',
            },
            {
              text: 'Persediaan Awal',
              to: { name: 'openingbalances' },
            },
            {
              text: 'Tambah',
              active: true
            },
          ],
        }
      },
      {
        path: ':id',
        name: 'openingbalances-show',
        component: () => import('@pages/openingbalances/Form.vue'),
        meta: {
          auth:true,
          pageTitle: 'Detail Persediaan Awal',
          isShow:true,
          navActiveLink:'openingbalances',
          breadcrumb: [
            {
              text: 'Inventori',
            },
            {
              text: 'Persediaan Awal',
              to: { name: 'openingbalances' },
            },
            {
              text: 'Detail',
              active: true
            },
          ],
        }
      },
      {
        path: ':id/edit',
        name: 'openingbalances-edit',
        component: () => import('@pages/openingbalances/Form.vue'),
        meta: {
          auth:true,
          pageTitle: 'Edit Persediaan Awal',
          navActiveLink: 'openingbalances',
          isEdit:true,
          breadcrumb: [
            {
              text: 'Inventori',
            },
            {
              text: 'Persediaan Awal',
              to: { name: 'openingbalances' },
            },
            {
              text: 'Edit',
              active: true
            },
          ],
        }
      }
    ]
  }
]