import regionals from './settings/regionals'
import companies from './settings/companies'
import users from './settings/users'
import banks from './settings/banks'
import taxes from './settings/taxes'
import suppliers from './settings/suppliers'
import partners from './settings/partners'
import commissions from './settings/commissions'
import expenses from './settings/expenses'
import units from './settings/units'
import itemcategories from './settings/itemcategories'
import items from './settings/items'
import itempackages from './settings/itempackages'
import itemtaxes from './settings/itemtaxes'

export default [
  {
    path: 'setting',
    redirect: { name: 'regionals' },
    component: { template: '<router-view />' },
    children: [
      ...regionals,
      ...companies,
      ...users,
      ...banks,
      ...taxes,
      ...suppliers,
      ...partners,
      ...commissions,
      ...expenses,
      ...units,
      ...itemcategories,
      ...items,
      ...itempackages,
      ...itemtaxes
    ]
  }
]