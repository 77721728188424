import openingbalances from './inventories/openingbalances'
import stockins from './inventories/stockins'
import purchases from './inventories/purchases'
import transferouts from './inventories/transferouts'
import receipts from './inventories/receipts'
import stockouts from './inventories/stockouts'
import stockopnames from './inventories/stockopnames'

export default [
  {
    path: 'inventori',
    redirect: { name: 'openingbalances' },
    component: { template: '<router-view />' },
    children: [
      ...openingbalances,
      ...stockins,
      ...purchases,
      ...transferouts,
      ...receipts,
      ...stockouts,
      ...stockopnames
    ]
  }
]