import axios from '@axios'

const state = {
  treeData:[]
}
const mutations = {}
const actions = {
  async store(store, payload) {
    try {
      const data = await axios.post(`v1/user_group_add`, payload)
      return Promise.resolve(data)
    } catch (error) {
      return Promise.reject(error)
    }
  },
  async update(store, payload) {
    try {
      const data = await axios.put(`v1/user_group_edit`, payload,{params:{id:payload.id}})
      return Promise.resolve(data)
    } catch (error) {
      return Promise.reject(error)
    }
  },
  async show(store, id) {
    try {
      const { data } = await axios.get(`v1/user_group_get`, {params:{id:id}})
      return Promise.resolve(data[0])
    } catch (error) {
      return Promise.reject(error)
    }
  },
  async destroy(store, payload) {
    try {
      const { data } = await axios.delete(`v1/user_group_delete`, {params:{id:payload.id,code: payload.name}})
      return Promise.resolve(data[0])
    } catch (error) {
      return Promise.reject(error)
    }
  },
  async getPrivilege(store, id) {
    try {
      const { data } = await axios.get(`v1/privilege_get`, {params:{id_group:id}})
      return Promise.resolve(data)
    } catch (error) {
      return Promise.reject(error)
    }
  },
  async storePrivilege(store, params) {
    try {
      const { data } = await axios.post(`v1/privilege_add`, params.data,{params:{id_group:params.id,name:params.name}})
      return Promise.resolve(data)
    } catch (error) {
      return Promise.reject(error)
    }
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}