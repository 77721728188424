import axios from '@axios'
import { getField, updateField } from 'vuex-map-fields'
import _ from 'lodash'
const defaultFilter = {
  id_branch:"",
  branch:null,
  supplier:null,
  date_1:"",
  date_2: "",
  id_supplier: "",
  status: ""
}
const state = {
  filter: JSON.parse(JSON.stringify(defaultFilter)),
  filterBranchOptions:[],
  filterSupplierOptions:[],
}
const mutations = {
  SET_OPTION_BRANCH(state, data) {
    if (_.isArray(data)) state.filterBranchOptions = data
    else state.filterBranchOptions = [data]
  },
  SET_OPTION_SUPPLIER(state, data) {
    if (_.isArray(data)) state.filterSupplierOptions = data
    else state.filterSupplierOptions = [data]
  },
  updateField
}
const getters = {
  getField,
  isFiltered(state) {
    return !_.isMatch(state.filter,defaultFilter)
  }
}
const actions = {
  async store(store, payload) {
    try {
      const data = await axios.post(`v1/purchase_add`, payload)
      return Promise.resolve(data)
    } catch (error) {
      return Promise.reject(error)
    }
  },
  async update(store, payload) {
    try {
      const data = await axios.put(`v1/purchase_edit`, payload,{params:{id:payload.id,code:payload.code}})
      return Promise.resolve(data)
    } catch (error) {
      return Promise.reject(error)
    }
  },
  async approve(store, payload) {
    try {
      const data = await axios.put(`v1/purchase_approval`, payload,{params:{id:payload.id,code:payload.code}})
      return Promise.resolve(data)
    } catch (error) {
      return Promise.reject(error)
    }
  },
  async show(store, id) {
    try {
      const { data } = await axios.get(`v1/purchase_get`, {params:{id:id}})
      return Promise.resolve(data[0])
    } catch (error) {
      return Promise.reject(error)
    }
  },
  async destroy(store, payload) {
    try {
      const { data } = await axios.delete(`v1/purchase_delete`, {params:{id:payload.id,code: payload.name}})
      return Promise.resolve(data[0])
    } catch (error) {
      return Promise.reject(error)
    }
  },
  async selectOptions({commit}, search) {
    try {
      const { data } = await axios.get(`v1/purchase_select2`, { params: { name: search||'' } })
      commit('INSERT_SELECT2', data)
      return Promise.resolve(data)
    } catch (error) {
      return Promise.reject(error)
    }
  },

}

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions
}