export default [
  {
    path: 'partner-pembayaran',
    redirect: { name: 'partners' },
    component: { template: '<router-view />' },
    children: [
      {
        path: '/',
        name: 'partners',
        component: () => import('@pages/partners/Index.vue'),
        meta: {
          auth:true,
          pageTitle: 'Mitra Pembayaran',
          breadcrumb: [
            {
              text: 'Setting',
            },
            {
              text: 'Mitra Pembayaran',
              active: true
            },
          ],
        }
      },
      {
        path: 'tambah',
        name: 'partners-add',
        component: () => import('@pages/partners/Form.vue'),
        meta: {
          auth:true,
          pageTitle: 'Tambah Mitra Pembayaran',
          navActiveLink:'partners',
          breadcrumb: [
            {
              text: 'Setting',
            },
            {
              text: 'Mitra Pembayaran',
              to: { name: 'partners' },
            },
            {
              text: 'Tambah',
              active: true
            },
          ],
        }
      },
      // {
      //   path: ':id',
      //   name: 'partners-show',
      //   component: () => import('@pages/partners/Show.vue'),
      //   meta: {
      //     auth:true,
      //     pageTitle: 'Detail Mitra Pembayaran',
      //     navActiveLink:'partners',
      //     breadcrumb: [
      //       {
      //         text: 'Setting',
      //       },
      //       {
      //         text: 'Mitra Pembayaran',
      //         to: { name: 'partners' },
      //       },
      //       {
      //         text: 'Detail',
      //         active: true
      //       },
      //     ],
      //   }
      // },
      {
        path: ':id',
        name: 'partners-edit',
        component: () => import('@pages/partners/Form.vue'),
        meta: {
          auth:true,
          pageTitle: 'Edit Mitra Pembayaran',
          navActiveLink:'partners',
          breadcrumb: [
            {
              text: 'Setting',
            },
            {
              text: 'Mitra Pembayaran',
              to: { name: 'partners' },
            },
            {
              text: 'Edit',
              active: true
            },
          ],
        }
      }
    ]
  }
]