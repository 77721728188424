export default [
  {
    path: 'pajak',
    redirect: { name: 'taxes' },
    component: { template: '<router-view />' },
    children: [
      {
        path: '/',
        name: 'taxes',
        component: () => import('@pages/taxes/Index.vue'),
        meta: {
          auth:true,
          pageTitle: 'Pajak',
          breadcrumb: [
            {
              text: 'Setting',
            },
            {
              text: 'Pajak',
              active: true
            },
          ],
        }
      },
      {
        path: 'tambah',
        name: 'taxes-add',
        component: () => import('@pages/taxes/Form.vue'),
        meta: {
          auth:true,
          pageTitle: 'Tambah Pajak',
          navActiveLink:'taxes',
          breadcrumb: [
            {
              text: 'Setting',
            },
            {
              text: 'Pajak',
              to: { name: 'taxes' },
            },
            {
              text: 'Tambah',
              active: true
            },
          ],
        }
      },
      {
        path: ':id',
        name: 'taxes-edit',
        component: () => import('@pages/taxes/Form.vue'),
        meta: {
          auth:true,
          pageTitle: 'Edit Pajak',
          navActiveLink:'taxes',
          breadcrumb: [
            {
              text: 'Setting',
            },
            {
              text: 'Pajak',
              to: { name: 'taxes' },
            },
            {
              text: 'Edit',
              active: true
            },
          ],
        }
      }
    ]
  }
]