import axios from '@axios'

const state = {
  lists:[]
}
const mutations = {
  INSERT_LISTS(state,data) {
    state.lists = data
  }
}
const actions = {
  async store(store, payload) {
    try {
      const data = await axios.post(`v1/payment_partner_add`, payload)
      return Promise.resolve(data)
    } catch (error) {
      return Promise.reject(error)
    }
  },
  async update(store, payload) {
    try {
      const data = await axios.put(`v1/payment_partner_edit`, payload,{params:{id:payload.id,code:payload.name}})
      return Promise.resolve(data)
    } catch (error) {
      return Promise.reject(error)
    }
  },
  async show(store, id) {
    try {
      const { data } = await axios.get(`v1/payment_partner_get`, {params:{id:id}})
      return Promise.resolve(data[0])
    } catch (error) {
      return Promise.reject(error)
    }
  },
  async getSelect2(store,name="") {
    try {
      const { data } = await axios.get(`v1/payment_partner_select2`, {params:{name:name}})
      return Promise.resolve(data)
    } catch (error) {
      return Promise.reject(error)
    }
  },
  async destroy(store, payload) {
    try {
      const { data } = await axios.delete(`v1/payment_partner_delete`, {params:{id:payload.id,code: payload.name}})
      return Promise.resolve(data[0])
    } catch (error) {
      return Promise.reject(error)
    }
  },
  async getList({commit}) {
    try {
      const { data } = await axios.get(`v1/payment_partner_item_get`)
      commit('INSERT_LISTS', data)
      return Promise.resolve(data)
    } catch (error) {
      return Promise.reject(error)
    }
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}