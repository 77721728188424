import axios from '@axios'
import moment from 'moment'

const state = {
  profile: {},
  cek: [],
  defaultbranches:[]
}
const mutations = {
  AUTHENTICATE(state, data) {
    state.profile = data.cekAuth
    state.cek = data.cek
    if(data.cekAuth.id_branch) state.defaultbranches = [{value:data.cekAuth.id_branch,label:data.cekAuth.branch_name}]
    localStorage['bearerToken'] = data.cekAuth.token
    localStorage['userProfile'] = JSON.stringify(data.cekAuth)
    localStorage['tokenTimeout'] = moment().add(1, 'h').format()
    localStorage['privileges'] = JSON.stringify(data.cek)
  },
  UNAUTHENTICATE(state) {
    state.profile = {}
    state.cek = []
    state.defaultbranches = []
    localStorage.removeItem('bearerToken')
    localStorage.removeItem('userProfile')
    localStorage.removeItem('tokenTimeout')
    localStorage.removeItem('privileges')
  },
  ASSIGN_PROFILE(state) {
    const data = JSON.parse(localStorage.userProfile || "{}")
    state.profile = data
    if(data.id_branch) state.defaultbranches = [{value:data.id_branch,label:data.branch_name}]
  },
  ASSIGN_PRIVILEGE(state) {
    state.cek = JSON.parse( localStorage.privileges || "[]" )
  }
}
const getters = {}
const actions = {
  async authenticate({ commit }, payload) {
    try {
      const { data } = await axios.post(`v1/authenticate`, payload)
      commit('AUTHENTICATE', data[0])
      return Promise.resolve(data[0])
    } catch (error) {
      return Promise.reject(error)
    }
  },
  unauthenticate({ commit }) {
    commit('UNAUTHENTICATE')
    return Promise.resolve("Success")
  }
}

export default {
  state,
  mutations,
  getters,
  actions,
  namespaced:true
}