import Vue from 'vue'
import Vuex from 'vuex'

// Modules
import app from './app'
import appConfig from './app-config'
import verticalMenu from './vertical-menu'

import auth from './modules/auth'
import regionals from './modules/regionals'
import companies from './modules/companies'
import users from './modules/users'
import usergroups from './modules/usergroups'
import banks from './modules/banks'
import taxes from './modules/taxes'
import suppliers from './modules/suppliers'
import partners from './modules/partners'
import commissions from './modules/commissions'
import expenses from './modules/expenses'
import units from './modules/units'
import itemcategories from './modules/itemcategories'
import items from './modules/items'
import utils from './modules/utils.js'
import itempackages from './modules/itempackages.js'
import itemtaxes from './modules/itemtaxes.js'
import openingbalances from './modules/openingbalances.js'
import stockins from './modules/stockins.js'
import purchases from './modules/purchases.js'
import transferouts from './modules/transferouts.js'
import receipts from './modules/receipts.js'
import stockouts from './modules/stockouts.js'
import stockopnames from './modules/stockopnames.js'
import operationalins from './modules/operationalins.js'
import operationalouts from './modules/operationalouts.js'
import performreports from './modules/performreports.js'
import incomereports from './modules/incomereports.js'
import stockcardreports from './modules/stockcardreports.js'
import operationalreports from './modules/operationalreports.js'
import invbalancereports from './modules/invbalancereports.js'
import cashpositionreports from './modules/cashpositionreports.js'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    app,
    appConfig,
    verticalMenu,
    utils,

    auth,
    // Settings
    regionals,
    companies,
    users,
    usergroups,
    banks,
    taxes,
    suppliers,
    partners,
    commissions,
    expenses,
    units,
    itemcategories,
    items,
    itempackages,
    itemtaxes,
    // Inventories
    openingbalances,
    stockins,
    purchases,
    transferouts,
    receipts,
    stockouts,
    stockopnames,
    // Operationals
    operationalins,
    operationalouts,
    // Reports
    performreports,
    incomereports,
    stockcardreports,
    operationalreports,
    invbalancereports,
    cashpositionreports
  },
  strict: process.env.DEV,
})
