export default [
  {
    path: 'laporan',
    redirect: { name: 'performreports' },
    component: { template: '<router-view />' },
    children: [
      {
        path: 'performa',
        name: 'performreports',
        component: () => import('@pages/reports/Perform.vue'),
        meta: {
          auth:true,
          pageTitle: 'Laporan Performa',
          breadcrumb: [
            {
              text: 'Laporan',
            },
            {
              text: 'Laporan Performa',
              active: true
            },
          ],
        }
      },
      {
        path: 'laba-rugi',
        name: 'incomestatements',
        component: () => import('@pages/reports/IncomeStatement.vue'),
        meta: {
          auth:true,
          pageTitle: 'Laporan Laba Rugi',
          breadcrumb: [
            {
              text: 'Laporan',
            },
            {
              text: 'Laporan Laba Rugi',
              active: true
            },
          ],
        }
      },
      {
        path: 'kartu-stok',
        name: 'stockcards',
        component: () => import('@pages/reports/StockCard.vue'),
        meta: {
          auth:true,
          pageTitle: 'Laporan Kartu Stok',
          breadcrumb: [
            {
              text: 'Laporan',
            },
            {
              text: 'Laporan Kartu Stok',
              active: true
            },
          ],
        }
      },
      {
        path: 'operasional',
        name: 'operationalreports',
        component: () => import('@pages/reports/Operational.vue'),
        meta: {
          auth:true,
          pageTitle: 'Laporan Operasional',
          breadcrumb: [
            {
              text: 'Laporan',
            },
            {
              text: 'Laporan Operasional',
              active: true
            },
          ],
        }
      },
      {
        path: 'invbalance',
        name: 'invbalancereports',
        component: () => import('@pages/reports/InvBalance.vue'),
        meta: {
          auth:true,
          pageTitle: 'Laporan Nilai Stok',
          breadcrumb: [
            {
              text: 'Laporan',
            },
            {
              text: 'Laporan Nilai Stok',
              active: true
            },
          ],
        }
      },
      {
        path: 'cash-position',
        name: 'cashpositionreports',
        component: () => import('@pages/reports/CashPosition.vue'),
        meta: {
          auth:true,
          pageTitle: 'Laporan Posisi Kas',
          breadcrumb: [
            {
              text: 'Laporan',
            },
            {
              text: 'Laporan Posisi Kas',
              active: true
            },
          ],
        }
      },
      {
        path: 'view-performa',
        name: 'view-report-perform',
        component: () => import('@pages/reports/view/Perform.vue'),
        meta: {
          auth: false,
          layout: 'full',
        }
      },
      {
        path: 'view-laba-rugi',
        name: 'view-incomestatements',
        component: () => import('@pages/reports/view/IncomeStatement.vue'),
        meta: {
          auth: false,
          layout: 'full',
        }
      },
      {
        path: 'view-kartu-stok',
        name: 'view-report-stockcard',
        component: () => import('@pages/reports/view/StockCard.vue'),
        meta: {
          auth: false,
          layout: 'full',
        }
      },
      {
        path: 'view-operasional',
        name: 'view-report-operational',
        component: () => import('@pages/reports/view/Operational.vue'),
        meta: {
          auth: false,
          layout: 'full',
        }
      },
      {
        path: 'view-inv-balance',
        name: 'view-inv-balance',
        component: () => import('@pages/reports/view/InvBalance.vue'),
        meta: {
          auth: false,
          layout: 'full',
        }
      },
      {
        path: 'view-cash-position',
        name: 'view-cash-position',
        component: () => import('@pages/reports/view/CashPosition.vue'),
        meta: {
          auth: false,
          layout: 'full',
        }
      }
    ]
  }
]