export default [
  {
    path: 'satuan',
    redirect: { name: 'units' },
    component: { template: '<router-view />' },
    children: [
      {
        path: '/',
        name: 'units',
        component: () => import('@pages/units/Index.vue'),
        meta: {
          auth:true,
          pageTitle: 'Satuan',
          breadcrumb: [
            {
              text: 'Setting',
            },
            {
              text: 'Satuan',
              active: true
            },
          ],
        }
      },
      {
        path: 'tambah',
        name: 'units-add',
        component: () => import('@pages/units/Form.vue'),
        meta: {
          auth:true,
          pageTitle: 'Tambah Satuan',
          navActiveLink:'units',
          breadcrumb: [
            {
              text: 'Setting',
            },
            {
              text: 'Satuan',
              to: { name: 'units' },
            },
            {
              text: 'Tambah',
              active: true
            },
          ],
        }
      },
      {
        path: ':id',
        name: 'units-edit',
        component: () => import('@pages/units/Form.vue'),
        meta: {
          auth:true,
          pageTitle: 'Edit Satuan',
          navActiveLink:'units',
          breadcrumb: [
            {
              text: 'Setting',
            },
            {
              text: 'Satuan',
              to: { name: 'units' },
            },
            {
              text: 'Edit',
              active: true
            },
          ],
        }
      }
    ]
  }
]