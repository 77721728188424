import { getField, updateField } from 'vuex-map-fields'
import _ from 'lodash'
const defaultFilter = {
  id_regional: [],
  name_regional: "",
  id_branch: [],
  name_branch: "",
  id_expense: [],
  name_expense: "",
  date_1: "",
  date_2: "",
}

const state = {
  filter: JSON.parse(JSON.stringify(defaultFilter)),
  filterBranchOptions:[],
  filterRegionalOptions:[],
  filterExpenseOptions:[],
}
const mutations = {
  SET_OPTION_BRANCH(state, data) {
    if (_.isArray(data)) state.filterBranchOptions = data
    else state.filterBranchOptions = [data]
  },
  SET_OPTION_REGIONAL(state, data) {
    if (_.isArray(data)) state.filterRegionalOptions = data
    else state.filterRegionalOptions = [data]
  },
  SET_OPTION_EXPENSE(state, data) {
    if (_.isArray(data)) state.filterExpenseOptions = data
    else state.filterExpenseOptions = [data]
  },
  updateField
}
const getters = {
  getField,
  isFiltered(state) {
    return !_.isMatch(state.filter,defaultFilter)
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  getters,
}