import axios from '@axios'

const state = {
  lists:[]
}
const mutations = {
  INSERT_LISTS(state,data) {
    state.lists = data
  }
}
const actions = {
  async store(store, payload) {
    try {
      const data = await axios.post(`v1/branch_add`, payload)
      return Promise.resolve(data)
    } catch (error) {
      return Promise.reject(error)
    }
  },
  async update(store, payload) {
    try {
      const data = await axios.put(`v1/branch_edit`, payload,{params:{id:payload.id,code:payload.code}})
      return Promise.resolve(data)
    } catch (error) {
      return Promise.reject(error)
    }
  },
  async show(store, id) {
    try {
      const { data } = await axios.get(`v1/branch_get`, {params:{id:id}})
      return Promise.resolve(data[0])
    } catch (error) {
      return Promise.reject(error)
    }
  },
  async getNumberDetail(store, params) {
    try {
      const { data } = await axios.get(`v1/format_no_details`, {params:params})
      return Promise.resolve(data)
    } catch (error) {
      return Promise.reject(error)
    }
  },
  async destroy(store, payload) {
    try {
      const { data } = await axios.delete(`v1/branch_delete`, {params:{id:payload.id,code: payload.name}})
      return Promise.resolve(data[0])
    } catch (error) {
      return Promise.reject(error)
    }
  },
  async storeNumbering(store, payload) {
    try {
      const data = await axios.post(`v1/format_no_add`, payload)
      return Promise.resolve(data)
    } catch (error) {
      return Promise.reject(error)
    }
  },
  async updateNumbering(store, payload) {
    try {
      const data = await axios.put(`v1/format_no_edit`, payload,{params:{id:payload.id,name_format:payload.name_format}})
      return Promise.resolve(data)
    } catch (error) {
      return Promise.reject(error)
    }
  },
  async destroyNumbering(store, id) {
    try {
      const data = await axios.delete(`v1/format_no_delete`,{params:{id:id}})
      return Promise.resolve(data)
    } catch (error) {
      return Promise.reject(error)
    }
  },
  async getThreshold(store, params) {
    try {
      const { data } = await axios.get(`v1/storage_threshold_get`, {params:params})
      return Promise.resolve(data[0])
    } catch (error) {
      return Promise.reject(error)
    }
  },
  async storeThreshold(store, payload) {
    try {
      const data = await axios.post(`v1/storage_threshold_add`, payload,{params:{name:payload.name}})
      return Promise.resolve(data)
    } catch (error) {
      return Promise.reject(error)
    }
  },
  async getList({commit}) {
    try {
      const { data } = await axios.get(`v1/branch_item_get`)
      commit('INSERT_LISTS', data)
      return Promise.resolve(data)
    } catch (error) {
      return Promise.reject(error)
    }
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}