import Vue from 'vue'
import VueRouter from 'vue-router'

import settings from './sections/settings'
import inventories from './sections/inventories'
import operationals from './sections/operationals'
import reports from './sections/reports'

import moment from 'moment'
import store from '@/store/index'

Vue.use(VueRouter)

const router = new VueRouter({
  // mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/',
      redirect: {name:'home'},
    },
    {
      path: '/p',
      component: () => import('@/views/Main.vue'),
      redirect: { name: 'home' },
      children: [
        {
          path: 'dashboard',
          name: 'home',
          component: () => import('@/views/dashboard/Home.vue'),
          meta: {
            auth:true,
            pageTitle: 'Home',
            breadcrumb: [
              {
                text: 'Home',
                active: true,
              },
            ],
          },
        },
        ...settings,
        ...inventories,
        ...operationals,
        ...reports
      ]
    },
    {
      path: '/login',
      name: 'login',
      component: () => import('@/views/auth/Login.vue'),
      meta: {
        auth: false,
        pageTitle: 'Login',
        layout: 'full',
      },
    },
    {
      path: '/error-404',
      name: 'error-404',
      component: () => import('@/views/error/Error404.vue'),
      meta: {
        auth: false,
        layout: 'full',
      },
    },
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

router.beforeEach((to, from, next) => {
  const bearerToken = localStorage['bearerToken']
  if (to.meta.auth && !bearerToken) return next({ name: 'login' });
  const tokenTimeout = localStorage['tokenTimeout'];
  const isExpired = tokenTimeout && moment(tokenTimeout).valueOf() < moment().valueOf()

  if (to.meta.pageTitle) {
    window.document.title = `${to.meta.pageTitle} | IMS`
  } else {
    window.document.title = `IMS`
  }
  if (to.meta.auth && isExpired) {
    store.commit('auth/UNAUTHENTICATE')
    return next({ name: 'login' })
  }
  if (to.name == 'login' && localStorage['bearerToken'] && !isExpired) return next({ name: 'home' });

  if (to.meta.auth && !isExpired) {
    localStorage['tokenTimeout']=moment().add(1, 'h').format();
  }
  return next();
})

// router.afterEach((to) => {
//   if (to.meta.auth) store.dispatch('utils/getDraftNotification');
// })

export default router
