export default [
  {
    path: 'stok-masuk',
    redirect: { name: 'stockins' },
    component: { template: '<router-view />' },
    children: [
      {
        path: '/',
        name: 'stockins',
        component: () => import('@pages/stockins/Index.vue'),
        meta: {
          auth:true,
          pageTitle: 'Stok Masuk',
          breadcrumb: [
            {
              text: 'Inventori',
            },
            {
              text: 'Stok Masuk',
              active: true
            },
          ],
        }
      },
      {
        path: 'tambah',
        name: 'stockins-add',
        component: () => import('@pages/stockins/Form.vue'),
        meta: {
          auth:true,
          pageTitle: 'Tambah Stok Masuk',
          navActiveLink:'stockins',
          breadcrumb: [
            {
              text: 'Inventori',
            },
            {
              text: 'Stok Masuk',
              to: { name: 'stockins' },
            },
            {
              text: 'Tambah',
              active: true
            },
          ],
        }
      },
      {
        path: ':id',
        name: 'stockins-show',
        component: () => import('@pages/stockins/Form.vue'),
        meta: {
          auth: true,
          isShow: true,
          pageTitle: 'Detail Stok Masuk',
          navActiveLink:'stockins',
          breadcrumb: [
            {
              text: 'Inventori',
            },
            {
              text: 'Stok Masuk',
              to: { name: 'stockins' },
            },
            {
              text: 'Detail',
              active: true
            },
          ],
        }
      },
      {
        path: ':id/edit',
        name: 'stockins-edit',
        component: () => import('@pages/stockins/Form.vue'),
        meta: {
          auth: true,
          isEdit: true,
          pageTitle: 'Edit Stok Masuk',
          navActiveLink:'stockins',
          breadcrumb: [
            {
              text: 'Inventori',
            },
            {
              text: 'Stok Masuk',
              to: { name: 'stockins' },
            },
            {
              text: 'Edit',
              active: true
            },
          ],
        }
      },
      {
        path: ':id/approval',
        name: 'stockins-approval',
        component: () => import('@pages/stockins/Form.vue'),
        meta: {
          auth: true,
          isApprove: true,
          pageTitle: 'Approve Stok Masuk',
          navActiveLink:'stockins',
          breadcrumb: [
            {
              text: 'Inventori',
            },
            {
              text: 'Stok Masuk',
              to: { name: 'stockins' },
            },
            {
              text: 'Approval',
              active: true
            },
          ],
        }
      }
    ]
  }
]