export default [
  {
    path: 'stok-keluar',
    redirect: { name: 'stockouts' },
    component: { template: '<router-view />' },
    children: [
      {
        path: '/',
        name: 'stockouts',
        component: () => import('@pages/stockouts/Index.vue'),
        meta: {
          auth:true,
          pageTitle: 'Stok Keluar / Penggunaan',
          breadcrumb: [
            {
              text: 'Inventori',
            },
            {
              text: 'Stok Keluar / Penggunaan',
              active: true
            },
          ],
        }
      },
      {
        path: 'tambah',
        name: 'stockouts-add',
        component: () => import('@pages/stockouts/Form.vue'),
        meta: {
          auth: true,
          isAdd: true,
          pageTitle: 'Tambah Stok Keluar / Penggunaan',
          quickName: 'Stok Keluar',
          navActiveLink:'stockouts',
          breadcrumb: [
            {
              text: 'Inventori',
            },
            {
              text: 'Stok Keluar / Penggunaan',
              to: { name: 'stockouts' },
            },
            {
              text: 'Tambah',
              active: true
            },
          ],
        }
      },
      {
        path: ':id',
        name: 'stockouts-edit',
        component: () => import('@pages/stockouts/Form.vue'),
        meta: {
          auth: true,
          isEdit: true,
          pageTitle: 'Edit Stok Keluar / Penggunaan',
          navActiveLink:'stockouts',
          breadcrumb: [
            {
              text: 'Inventori',
            },
            {
              text: 'Stok Keluar / Penggunaan',
              to: { name: 'stockouts' },
            },
            {
              text: 'Edit',
              active: true
            },
          ],
        }
      },
    ]
  }
]