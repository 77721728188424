import axios from '@axios'
import { getField, updateField } from 'vuex-map-fields'
import _ from 'lodash'

const state = {
  filter: {
    id_branch:"",
    id_item:"",
    date_1:"",
    date_2:""
  },
  filterBranchOptions:[],
  filterUnitOptions:[],
}
const mutations = {
  SET_OPTION_BRANCH(state, data) {
    if (_.isArray(data)) state.filterBranchOptions = data
    else state.filterBranchOptions = [data]
  },
  SET_OPTION_UNIT(state, data) {
    if (_.isArray(data)) state.filterUnitOptions = data
    else state.filterUnitOptions = [data]
  },
  updateField
}
const getters = {
  getField
}
const actions = {
  async store(store, payload) {
    try {
      const data = await axios.post(`v1/opening_balance_add`, payload)
      return Promise.resolve(data)
    } catch (error) {
      return Promise.reject(error)
    }
  },
  async update(store, payload) {
    try {
      const data = await axios.put(`v1/opening_balance_edit`, payload,{params:{id:payload.id,code:payload.code}})
      return Promise.resolve(data)
    } catch (error) {
      return Promise.reject(error)
    }
  },
  async show(store, id) {
    try {
      const { data } = await axios.get(`v1/opening_balance_get`, {params:{id:id}})
      return Promise.resolve(data[0])
    } catch (error) {
      return Promise.reject(error)
    }
  },
  async destroy(store, payload) {
    try {
      const { data } = await axios.delete(`v1/opening_balance_delete`, {params:{id:payload.id,code: payload.name}})
      return Promise.resolve(data[0])
    } catch (error) {
      return Promise.reject(error)
    }
  },
  async selectOptions({commit}, search) {
    try {
      const { data } = await axios.get(`v1/opening_balance_select2`, { params: { name: search||'' } })
      commit('INSERT_SELECT2', data)
      return Promise.resolve(data)
    } catch (error) {
      return Promise.reject(error)
    }
  },

}

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions
}