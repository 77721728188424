import axios from '@axios'

const state = {}
const mutations = {}
const actions = {
  async store(store, payload) {
    try {
      const data = await axios.post(`v1/item_add`, payload)
      return Promise.resolve(data)
    } catch (error) {
      return Promise.reject(error)
    }
  },
  async storeStepTwo(store, payload) {
    try {
      const data = await axios.put(`v1/item_unit_edit`, payload, {params:{id:payload.id,code:payload.code}})
      return Promise.resolve(data)
    } catch (error) {
      return Promise.reject(error)
    }
  },
  async storeStepThree(store, payload) {
    try {
      const data = await axios.put(`v1/item_sale_prices_edit`, payload.data, {params:{id:payload.id,code:payload.code}})
      return Promise.resolve(data)
    } catch (error) {
      return Promise.reject(error)
    }
  },
  async update(store, payload) {
    try {
      const data = await axios.put(`v1/item_edit`, payload,{params:{id:payload.id,code:payload.name}})
      return Promise.resolve(data)
    } catch (error) {
      return Promise.reject(error)
    }
  },
  async show(store, id) {
    try {
      const { data } = await axios.get(`v1/item_get`, {params:{id:id}})
      return Promise.resolve(data[0])
    } catch (error) {
      return Promise.reject(error)
    }
  },
  async showStepTwo(store, id) {
    try {
      const { data } = await axios.get(`v1/item_unit_get`, {params:{id:id}})
      return Promise.resolve(data)
    } catch (error) {
      return Promise.reject(error)
    }
  },
  async showStepThree(store, id) {
    try {
      const { data } = await axios.get(`v1/item_sale_prices_get`, {params:{id:id}})
      return Promise.resolve(data)
    } catch (error) {
      return Promise.reject(error)
    }
  },
  async destroy(store, payload) {
    try {
      const { data } = await axios.delete(`v1/item_delete`, {params:{id:payload.id,code: payload.name}})
      return Promise.resolve(data[0])
    } catch (error) {
      return Promise.reject(error)
    }
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}