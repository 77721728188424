import axios from '@axios'

const state = {
  selectOptions:[]
}
const mutations = {
  INSERT_SELECT2(state,data) {
    state.selectOptions = data
  }
}
const actions = {
  async store(store, payload) {
    try {
      const data = await axios.post(`v1/unit_add`, payload)
      return Promise.resolve(data)
    } catch (error) {
      return Promise.reject(error)
    }
  },
  async update(store, payload) {
    try {
      const data = await axios.put(`v1/unit_edit`, payload,{params:{id:payload.id,code:payload.name}})
      return Promise.resolve(data)
    } catch (error) {
      return Promise.reject(error)
    }
  },
  async show(store, id) {
    try {
      const { data } = await axios.get(`v1/unit_get`, {params:{id:id}})
      return Promise.resolve(data[0])
    } catch (error) {
      return Promise.reject(error)
    }
  },
  async destroy(store, payload) {
    try {
      const { data } = await axios.delete(`v1/unit_delete`, {params:{id:payload.id,code: payload.name}})
      return Promise.resolve(data[0])
    } catch (error) {
      return Promise.reject(error)
    }
  },
  async selectOptions({commit}, search) {
    try {
      const { data } = await axios.get(`v1/unit_select2`, { params: { name: search||'' } })
      commit('INSERT_SELECT2', data)
      return Promise.resolve(data)
    } catch (error) {
      return Promise.reject(error)
    }
  },

}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}