export default [
  {
    path: 'penerimaan',
    redirect: { name: 'receipts' },
    component: { template: '<router-view />' },
    children: [
      {
        path: '/',
        name: 'receipts',
        component: () => import('@pages/receipts/Index.vue'),
        meta: {
          auth:true,
          pageTitle: 'Penerimaan',
          breadcrumb: [
            {
              text: 'Inventori',
            },
            {
              text: 'Penerimaan',
              active: true
            },
          ],
        }
      },
      {
        path: ':id',
        name: 'receipts-show',
        component: () => import('@pages/receipts/Form.vue'),
        meta: {
          auth: true,
          pageTitle: 'Detail Penerimaan',
          isShow: true,
          navActiveLink:'receipts',
          breadcrumb: [
            {
              text: 'Inventori',
            },
            {
              text: 'Penerimaan',
              to: { name: 'receipts' },
            },
            {
              text: 'Detail',
              active: true
            },
          ],
        }
      },
      {
        path: ':id/edit',
        name: 'receipts-edit',
        component: () => import('@pages/receipts/Form.vue'),
        meta: {
          auth: true,
          isEdit: true,
          pageTitle: 'Edit Penerimaan',
          navActiveLink:'receipts',
          breadcrumb: [
            {
              text: 'Inventori',
            },
            {
              text: 'Penerimaan',
              to: { name: 'receipts' },
            },
            {
              text: 'Edit',
              active: true
            },
          ],
        }
      },
      {
        path: ':id/dari-transfer',
        name: 'receipts-transfer',
        component: () => import('@pages/receipts/Form.vue'),
        meta: {
          auth: true,
          isTransfer: true,
          pageTitle: 'Tambah Penerimaan',
          navActiveLink:'receipts',
          breadcrumb: [
            {
              text: 'Inventori',
            },
            {
              text: 'Penerimaan',
              to: { name: 'receipts' },
            },
            {
              text: 'Tambah dari Transfer',
              active: true
            },
          ],
        }
      },
      {
        path: ':id/dari-pembelian',
        name: 'receipts-purchase',
        component: () => import('@pages/receipts/Form.vue'),
        meta: {
          auth: true,
          isPurchase: true,
          pageTitle: 'Tambah Penerimaan',
          navActiveLink:'receipts',
          breadcrumb: [
            {
              text: 'Inventori',
            },
            {
              text: 'Penerimaan',
              to: { name: 'receipts' },
            },
            {
              text: 'Tambah dari Pembelian',
              active: true
            },
          ],
        }
      },
      {
        path: ':id/sebagian',
        name: 'receipts-part',
        component: () => import('@pages/receipts/Form.vue'),
        meta: {
          auth: true,
          isPart: true,
          pageTitle: 'Tambah Penerimaan',
          navActiveLink:'receipts',
          breadcrumb: [
            {
              text: 'Inventori',
            },
            {
              text: 'Penerimaan',
              to: { name: 'receipts' },
            },
            {
              text: 'Tambah Sebagian',
              active: true
            },
          ],
        }
      },
    ]
  }
]