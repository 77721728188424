import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  methods: {
    toastSuccess(title,text=null) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: title,
          text: text,
          icon: 'CheckIcon',
          variant: 'success',
        },
      },{
        position:'bottom-right'
      })
    },
    toastError(title,text=null) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: title,
          text: text,
          icon: 'CheckIcon',
          variant: 'danger',
        },
      },{
        position:'bottom-right'
      })
    },
    toastWarning(title,text=null) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: title,
          text: text,
          icon: 'CheckIcon',
          variant: 'warning',
        },
      }, {
        position:'bottom-right'
      })
    },
  }
}